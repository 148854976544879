<template>
  <div id="app">
    <div class="centered-content">
      <h1>wizth://automation</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style>
/* Resetting default margin and padding */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

/* Base font size for mobile devices */
html {
  font-size: 16px;
}

/* Increase font size for larger screens */
@media (min-width: 768px) {
  html {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 35px;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 45px;
  }
}


@media (min-width: 2560px) {
  html {
    font-size: 65px;
  }
}

#app {
  font-family: 'Raleway', sans-serif;
  background: #20232a;
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

/* The centered content styling remains the same */
.centered-content {
  background: linear-gradient(45deg, #f06, #9f6);
  background-clip: text;
  color: transparent;
  text-shadow: 0px 3px 5px rgba(0,0,0,0.2);
}
</style>


